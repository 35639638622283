import axios from "axios"


// const baseUrl = 'https://ohadtech.com/accounts/admin/public/api/'
const baseUrl = 'https://accounts.easysell.pk/api/';
// const baseUrl = 'http://127.0.0.1:8000/api/'

const captchaUrl = 'https://accounts.easysell.pk/';
// const captchaUrl = 'http://127.0.0.1:8000/'
class AjaxService {

    getUrl = (url, id) => {
        if (id > 0) {
            return baseUrl + url, id
        }
        else {
            return baseUrl + url
        }
    }


    async get(url, id) {

        let token = localStorage.getItem('token')

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        let options = { method: 'GET', headers: headers }

        return fetch(this.getUrl(url, id), options)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw response
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error("error get data:", error)
            })
    }

    async filter(url, data) {
        let token = localStorage.getItem('token')

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        let options = { method: 'POST', headers: headers, body: JSON.stringify(data) }

        return fetch(this.getUrl(url), options)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw response
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error("error filter data:", error)
            })
    }

    async delete(url, id) {
        let token = localStorage.getItem('token')

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        let options = { method: 'DELETE', headers: headers }

        return fetch(this.getUrl(url) + id, options)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw response
            })
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error("error deleting data:", error)
            })
    }

    async post(url, data) {

        let token = localStorage.getItem('token')

        return fetch(this.getUrl(url), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    return response.json()
                }
                // throw response
            })
            .then((result) => {
                return result
            })
            .catch(error => {
                console.error("error post data:", error)
            })
    }

    async put(url, data, id) {
        let token = localStorage.getItem('token')

        return fetch(this.getUrl(url) + id, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw response
            })
            .then(result => {
                return result
            })
            .catch(error => {
                console.error("error put data:", error)
            })
    }

    async getAccessToken(username, password, captcha, captcha_original) {

        let data = {
            client_id: '98f49c7f-2736-40a4-8b31-456e8399076a',
            client_secret: '5wavpjDq56AG83QowPVwil4IyhFMFGhHGVc9jTw2',
            username: username,
            password: password,
            captcha: captcha,
            captcha_original: captcha_original,
            grant_type: 'password'
        };

        const uri = `${baseUrl}users/login`;
        let headers = {
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
        };
        let options = {
            method: 'POST',
            headers: headers,
            url: uri,
            data: data,
            // crossdomain: true
            // mode: 'no-cors',
            // withCredentials: false,
        }

        return axios(options).then(response => {
            return response;
        }).catch(function (error) {
            console.error("error fetchinng data:", error)
            return error.response.data
        });
    }


    async getCaptcha() {

        return fetch(`${captchaUrl}reload-captcha`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data && data.captcha) {
                    return data.captcha;
                } else {
                    throw new Error("Invalid captcha response");
                }
            })
            .catch(error => {
                console.error("Error fetching captcha:", error);
                return null;
            });
    }


}
const ajaxService = new AjaxService()

export default ajaxService