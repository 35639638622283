import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale } from "chart.js";
import { Space, Tag } from "antd";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const DonutChart = ({
    labels = ["Category 1", "Category 2", "Category 3"],
    dataValues = [30, 40, 30],
    colors = ["#FF6384", "#36A2EB", "#FFCE56"],
    size = 250,
    cutout = "70%",
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const tagStyle = {
        fontSize: isMobile ? 12 : 14,
        padding: isMobile ? "4px 8px" : "5px 10px",
    };

    const chartData = {
        labels,
        datasets: [
            {
                data: dataValues,
                backgroundColor: colors,
            },
        ],
    };

    return (
        <div style={{ textAlign: "center" }}>
            <Space
                size="middle"
                wrap={isMobile}
                direction={isMobile ? "vertical" : "horizontal"}
                style={{ marginBottom: 10 }}
            >
                {labels.map((label, index) => (
                    <Tag key={index} color={colors[index]} style={tagStyle}>
                        {label} ({dataValues[index]})
                    </Tag>
                ))}
            </Space>
            <div style={{ width: size, height: size, margin: "0 auto" }}>
                <Doughnut
                    data={chartData}
                    options={{
                        maintainAspectRatio: false,
                        cutout,
                        plugins: { legend: { display: false } },
                    }}
                />
            </div>
        </div>
    );
};

export default DonutChart;
