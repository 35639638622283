import React, { useEffect, useState, forwardRef } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, Input, notification, Row, Select, Space, Typography } from 'antd';
import ajaxService from '../services/ajax-services';
import TextArea from 'antd/es/input/TextArea';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../hooks';

const { Text, Title } = Typography
const { Option } = Select

const Dashboard = () => {

    const { width } = useWindowSize()

    const isMobile = width <= 768

    const [accounts, setAccounts] = useState([])
    const [entities, setEntities] = useState([])
    const [categories, setCategories] = useState([])

    let history = useHistory()
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const response = await ajaxService.post('transactions', values)
        if (response) {
            loadAccounts()
            loadEntities()
            form.resetFields();
            notification.open({ message: 'Transaction has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to add transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadAccounts = async () => {
        let data = await ajaxService.get('accounts')
        if (data) {
            setAccounts(data.filter(i => i.active == 1))
        }
    }

    const loadEntities = async () => {
        let data = await ajaxService.get('entities')
        if (data) {
            setEntities(data.filter(i => i.active == 1))
        }
    }

    const loadCategories = async () => {
        let data = await ajaxService.get('categories')
        if (data) {
            setCategories(data.filter(i => i.active == 1))
        }
    }

    useEffect(() => {
        loadCategories()
        loadAccounts()
        loadEntities()
    }, [])

    const renderDashboard = () => {
        return (
            <>
                <Card>
                    <Title level={4}>Dashboard</Title>
                    <Divider plain > Entities</Divider>
                    <Row gutter={16}>
                        {entities.map(entity => {
                            return (
                                <>
                                    <Col key={entity.id} span={3}>
                                        <a onClick={() => history.push({
                                            pathname: '/transactions',
                                            id: entity.id,
                                            type: 'entity'
                                        })}>
                                            <Card
                                                style={{ height: 100, marginBottom: '40px', background: '#003366' }}>
                                                <Text style={{ fontWeight: 'bolder', color: 'white' }} level={3}>{entity.name}</Text>
                                                <br />
                                                <Text style={{ fontWeight: 'lighter', color: 'white' }} level={3}>{entity.amount}</Text>
                                            </Card>
                                        </a>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>

                    <Divider plain> Accounts</Divider>
                    <Row gutter={16}>
                        {accounts.map(account => {
                            return (
                                <>
                                    <Col key={account.id} span={3}>
                                        <a onClick={() => history.push({
                                            pathname: '/transactions',
                                            id: account.id,
                                            type: 'account'
                                        })}>
                                            <Card
                                                style={{ height: 100, marginBottom: '40px', background: '#003366' }}>
                                                <Text style={{ fontWeight: 'bolder', color: 'white' }} level={3}>{account.name}</Text>
                                                <br />
                                                <Text style={{ fontWeight: 'lighter', color: 'white' }} level={3}>{account.amount}</Text>
                                            </Card>
                                        </a>
                                    </Col>
                                </>
                            )
                        })}

                    </Row>

                    <Divider plain> Create Transactions</Divider>
                    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                        <Row gutter={16} >
                            <Col span={6}></Col>
                            <Col span={12} >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item name='account_id' label="Account"
                                            labelCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Account is required',
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Select Account"
                                                showSearch allowClear
                                                style={{ width: '100%' }}
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {accounts.map(account => {
                                                    return <Option key={account.id} value={account.id}>{account.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={11}>
                                        <Form.Item name='entity_id' label="Entity"
                                            labelCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entity is required',
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Select Entity"
                                                showSearch allowClear
                                                style={{ width: '100%' }}
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {entities.map(entities => {
                                                    return <Option key={entities.id} value={entities.id}>{entities.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name='category_id' label="Category" labelCol={{ span: 24 }}>
                                            <Select
                                                placeholder="Select Category"
                                                showSearch allowClear
                                                style={{ width: '100%' }}
                                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {categories.map(category => {
                                                    return <Option key={category.id} value={category.id}>{category.name}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={11}>
                                        <Form.Item name='amount' label="Amount"
                                            labelCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Amount is required',
                                                },
                                            ]}>
                                            <Input placeholder='Enter Amount' type='number' step="0.01" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name='date' label="Date"
                                            labelCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Date is required',
                                                },
                                            ]}>
                                            <DatePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={11}>
                                        <Form.Item name='for_the_month' label="For the Month"
                                            labelCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'For the Month is required',
                                                },
                                            ]}>
                                            <DatePicker picker="month" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name='description' label="Description"
                                            labelCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Description is required',
                                                },
                                            ]}>
                                            <TextArea></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={9}></Col>
                                    <Form.Item>
                                        <Space>
                                            <Button htmlType="submit" type="primary">Submit</Button>
                                            <Input type='reset' value="Clear" style={{ cursor: 'pointer' }} />
                                        </Space>
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Form >

                </Card >
            </>
        );
    }

    const renderDashboardMobile = () => {
        return (
            <>
                <Card>
                    <Title level={4}>Dashboard</Title>
                    <Divider plain> Entities</Divider>
                    <Row gutter={16}>
                        {entities.map(entity => {
                            return (
                                <>
                                    <Col key={entity.id} span={12}>
                                        <a onClick={() => history.push({
                                            pathname: '/transactions',
                                            id: entity.id,
                                            type: 'entity'
                                        })}>
                                            <Card
                                                style={{ height: 100, marginBottom: '40px', background: '#003366' }}>
                                                <Text style={{ fontWeight: 'bolder', color: 'white' }} level={3}>{entity.name}</Text>
                                                <br />
                                                <Text style={{ fontWeight: 'lighter', color: 'white' }} level={3}>{entity.amount}</Text>
                                            </Card>
                                        </a>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>

                    <Divider plain> Accounts</Divider>
                    <Row gutter={16}>
                        {accounts.map(account => {
                            return (
                                <>
                                    <Col key={account.id} span={12}>
                                        <a onClick={() => history.push({
                                            pathname: '/transactions',
                                            id: account.id,
                                            type: 'account'
                                        })}>
                                            <Card style={{ height: 100, marginBottom: '40px', background: '#003366' }}>
                                                <Text style={{ fontWeight: 'bolder', color: 'white' }} level={3}>{account.name}</Text>
                                                <br />
                                                <Text style={{ fontWeight: 'lighter', color: 'white' }} level={3}>{account.amount}</Text>
                                            </Card>
                                        </a>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>

                    <Divider plain> Create Transactions</Divider>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}>
                        <Row gutter={16} >
                            <Col span={24}>
                                <Form.Item name='account_id' label="Account"
                                    labelCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Account is required',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="Select Account"
                                        showSearch allowClear
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {accounts.map(account => {
                                            return <Option key={account.id} value={account.id}>{account.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='entity_id' label="Entity"
                                    labelCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Entity is required',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="Select Entity"
                                        showSearch allowClear
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {entities.map(entities => {
                                            return <Option key={entities.id} value={entities.id}>{entities.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='category_id' label="Category" labelCol={{ span: 24 }}>
                                    <Select
                                        placeholder="Select Category"
                                        showSearch allowClear
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {categories.map(category => {
                                            return <Option key={category.id} value={category.id}>{category.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='amount' label="Amount"
                                    labelCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Amount is required',
                                        },
                                    ]}>
                                    <Input placeholder='Enter Amount' type='number' step="0.01" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='date' label="Date"
                                    labelCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Date is required',
                                        },
                                    ]}>

                                    <DatePicker style={{ width: '100%' }} />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='for_the_month' label="For the Month"
                                    labelCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'For the Month is required',
                                        },
                                    ]}>
                                    <DatePicker picker="month" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name='description' label="Description"
                                    labelCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Description is required',
                                        },
                                    ]}>
                                    <TextArea></TextArea>
                                </Form.Item>
                            </Col>

                            <Row>
                                <Col span={9}></Col>
                                <Form.Item>
                                    <Space>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Input type='reset' value="Clear" style={{ cursor: 'pointer' }} />
                                    </Space>
                                </Form.Item>
                            </Row>

                        </Row>
                    </Form>

                </Card>
            </>
        );
    }

    const renderContent = () => {
        if (isMobile) {
            return renderDashboardMobile()
        } else {
            return renderDashboard()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default Dashboard;