import React from 'react';
import { Row, Col, Card, Typography, Empty } from 'antd';

const { Paragraph, Text } = Typography;

const MobileCards = ({ data }) => {
    if (!data || data.length === 0) {
        return (
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Card>
                        <Empty description="No Data Found" />
                    </Card>
                </Col>
            </Row>
        );
    }

    return (
        <Row gutter={[16, 16]}>
            {data.map(item => (
                <Col key={item.category_name} xs={24}>
                    <Card title={item.category_name} variant="outlined">
                        <Paragraph>
                            Amount:&nbsp;
                            {item.amount >= 0 ? (
                                <Text style={{ color: 'green' }}>{item.amount}</Text>
                            ) : (
                                <Text style={{ color: 'red' }}>{item.amount}</Text>
                            )}
                        </Paragraph>
                        <Paragraph>
                            Budget:&nbsp;
                            {item.budget >= 0 ? (
                                <Text style={{ color: 'green' }}>{item.budget}</Text>
                            ) : (
                                <Text style={{ color: 'red' }}>{item.budget}</Text>
                            )}
                        </Paragraph>
                        <Paragraph>
                            Difference:&nbsp;
                            {item.difference >= 0 ? (
                                <Text style={{ color: 'green' }}>{item.difference}</Text>
                            ) : (
                                <Text style={{ color: 'red' }}>{item.difference}</Text>
                            )}
                        </Paragraph>
                        <Paragraph>
                            Percentage:&nbsp;
                            <Text style={{ color: 'blue' }}>{item.percentage}</Text>
                        </Paragraph>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default MobileCards;
