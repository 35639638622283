import { Button, Card, Col, DatePicker, Form, notification, Row, Select, Typography, Table, Space, Tag } from 'antd';
import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from '@ant-design/icons';
import ajaxService from '../../../services/ajax-services';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DonutChart from '../../charts/donut';
import MobileCards from './mobile/card';
import MobileFilter from './mobile/filter';
import { useWindowSize } from '../../../hooks';

dayjs.extend(customParseFormat);

const { Title, Text } = Typography;
const { Option } = Select;


const MonthlyCategoricalReport = () => {

    const { width } = useWindowSize()

    const isMobile = width <= 768

    const [form] = Form.useForm();
    const [entities, setEntities] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const columns = [
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) =>
                amount >= 0 ? (
                    <Text style={{ color: 'green' }}>{amount}</Text>
                ) : (
                    <Text style={{ color: 'red' }}>{amount}</Text>
                ),
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            render: (budget) =>
                budget >= 0 ? (
                    <Text style={{ color: 'green' }}>{budget}</Text>
                ) : (
                    <Text style={{ color: 'red' }}>{budget}</Text>
                ),
        },
        {
            title: 'Difference',
            dataIndex: 'difference',
            key: 'difference',
            render: (difference) =>
                difference >= 0 ? (
                    <Text style={{ color: 'green' }}>{difference}</Text>
                ) : (
                    <Text style={{ color: 'red' }}>{difference}</Text>
                ),
        },
        {
            title: 'Percentage',
            dataIndex: 'percentage',
            key: 'percentage',
            render: (percentage) => <Text style={{ color: 'blue' }}>{percentage}</Text>,
        },
    ];

    const handleSearch = async () => {
        try {
            const values = await form.validateFields();
            let formData = {};
            if (values.entity_id) {
                formData['entity'] = values.entity_id;
            }
            if (values.for_the_month) {
                formData['for_the_month'] = values.for_the_month;
            }
            const response = await ajaxService.filter('monthlycategoricalreport/filter', formData);
            if (response !== undefined) {
                setDataSource(response);
            } else {
                notification.open({
                    message: 'No transaction found...',
                    icon: <CloseCircleOutlined style={{ color: 'red' }} />,
                });
            }
        } catch (error) {
            console.log("Validation failed:", error);
        }
    };

    const handleClear = () => {
        form.resetFields();
        setDataSource([]);
    };

    const loadEntities = async () => {
        const response = await ajaxService.get('entities');
        if (response) {
            const data = response.filter(i => i.active === 1);
            setEntities(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
    };

    useEffect(() => {
        loadEntities();
    }, []);

    const renderFilters = () => {
        if (isMobile) {
            // Mobile-friendly filter layout: single column and full-width buttons
            return (
                <MobileFilter
                    form={form}
                    entities={entities}
                    handleSearch={handleSearch}
                    handleClear={handleClear}
                />
            );
        } else {
            // Desktop filter layout: more spaced out using rows and columns
            return (
                <Card style={{ marginBottom: 16 }}>
                    <Title level={4}>Monthly Categorical Report</Title>
                    <Form form={form} layout="vertical" style={{ marginTop: "20px" }}>
                        <Row gutter={16} align="middle" style={{ marginBottom: "20px" }}>
                            <Col span={2}>
                                <Title level={5} style={{ fontWeight: 400, margin: 0 }}>Select Entity:</Title>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="entity_id" style={{ marginBottom: 0 }}>
                                    <Select placeholder="Select Entity" showSearch allowClear style={{ width: "100%" }}>
                                        {entities.map(entity => (
                                            <Option key={entity.id} value={entity.id}>
                                                {entity.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} align="middle">
                            <Col span={2}>
                                <Title level={5} style={{ fontWeight: 400, margin: 0 }}>For the Month:</Title>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="for_the_month" style={{ marginBottom: 0 }}>
                                    <DatePicker picker="month" style={{ width: '100%' }} />

                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Space>
                                        <Button type="primary" onClick={handleSearch}>Search</Button>
                                        <Button onClick={handleClear}>Clear</Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </Card>
            );
        }
    };



    const renderChart = () => {
        const chartLabels = dataSource.map(item => item.category_name);
        const chartData = dataSource.map(item => item.amount);
        const baseColors = [
            "#ff6384", "#36a2eb", "#cc65fe", "#ffce56",
            "#2ecc71", "#3498db", "#e74c3c", "#9b59b6",
            "#1abc9c", "#f39c12"
        ];
        const chartColors = chartLabels.map((_, index) => baseColors[index % baseColors.length]);

        return (
            <Card
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "16px",
                    marginTop: 16,
                }}
            >
                <Title level={4}>Categorized Distribution</Title>
                <DonutChart
                    labels={chartLabels}
                    dataValues={chartData}
                    colors={chartColors}
                    width="300px"
                    height="300px"
                    size={300}
                />
            </Card>
        );
    };


    const renderCategoriesTable = () => {
        const totalBudget = dataSource.reduce((sum, category) => sum + parseFloat(category.budget || 0), 0).toFixed(2);
        const totalAmount = dataSource.reduce((sum, category) => sum + parseFloat(category.amount || 0), 0).toFixed(2);
        const totalDifference = dataSource.reduce((sum, category) => sum + parseFloat(category.difference || 0), 0).toFixed(2);

        return (
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                summary={() => (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={1} />
                        <Table.Summary.Cell index={2}>
                            <Text strong style={{ fontSize: "14px", color: "blue" }}>{totalAmount}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                            <Text strong style={{ fontSize: "14px", color: "blue" }}>{totalBudget}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                            <Text strong style={{ fontSize: "14px", color: "blue" }}>{totalDifference}</Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                )}
            />
        );
    };


    const renderCategorizedList = () => {
        return (
            <>
                {renderFilters()}
                {isMobile ? (
                    <MobileCards data={dataSource} />
                ) : (
                    renderCategoriesTable()
                )}
                {renderChart()}
            </>
        );
    };

    return <>{renderCategorizedList()}</>;
};

export default MonthlyCategoricalReport;
