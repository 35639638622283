import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import MainLayout from '../layout';
import Auth from "../layout/auth";
import Accounts from "../pages/account";
import AccountForm from "../pages/account/form";
import ForgotPassword from "../pages/auth/forgot_password";
import Login from "../pages/auth/login";
import PandingAproval from "../pages/auth/pandingAproval";
import PasswordReset from "../pages/auth/password_reset";
import Register from "../pages/auth/register";
import Categories from "../pages/category";
import CategoryForm from "../pages/category/form";
import Dashboard from "../pages/dashboard";
import Entities from "../pages/entity";
import EntityForm from "../pages/entity/form";
import Transactions from "../pages/transaction";
import ChangePasswordForm from "../pages/user/change_password";
import CategoryReport from "../pages/reports/month";
import MonthlyCategoricalReport from "../pages/reports/categorized";


const AuthRedirect = ({ destination }) => {
    return <Route render={(props) => (<Redirect to={{ pathname: destination, state: { from: props.location } }} />)} />;
}

const wrapper = (Layout, Component) => {

    let accessToken = localStorage.getItem('token');

    const redirect = <AuthRedirect destination={'/login'}></AuthRedirect>

    if (accessToken == null) return redirect;

    return (
        <Layout>
            <Component />
        </Layout>
    )
}

export default function Navigation() {
    return (
        <Router>

            <Route exact path={`/login`} component={() => <Auth><Login /></Auth>} />
            <Route exact path={`/register`} component={() => <Auth><Register /></Auth>} />
            <Route exact path={`/pandingAproval`} component={() => <Auth><PandingAproval /></Auth>} />
            <Route exact path={`/forgotPassword`} component={() => <Auth><ForgotPassword /></Auth>} />
            <Route exact path={`/password/reset/:token?`} component={() => <Auth><PasswordReset /></Auth>} />

            <Route exact path={`/`} component={() => wrapper(MainLayout, Dashboard)} />
            <Route exact path={`/categories`} component={() => wrapper(MainLayout, Categories)} />
            <Route exact path={`/categories/form/:id?`} component={() => wrapper(MainLayout, CategoryForm)} />
            <Route exact path={`/accounts`} component={() => wrapper(MainLayout, Accounts)} />
            <Route exact path={`/accounts/form/:id?`} component={() => wrapper(MainLayout, AccountForm)} />
            <Route exact path={`/entities`} component={() => wrapper(MainLayout, Entities)} />
            <Route exact path={`/entities/form/:id?`} component={() => wrapper(MainLayout, EntityForm)} />
            <Route exact path={`/transactions`} component={() => wrapper(MainLayout, Transactions)} />
            <Route exact path={`/reports/monthly-categorical-reports`} component={() => wrapper(MainLayout, MonthlyCategoricalReport)} />
            <Route exact path={`/reports/category-reports`} component={() => wrapper(MainLayout, CategoryReport)} />
            <Route exact path={`/change-password`} component={() => wrapper(MainLayout, ChangePasswordForm)} />
        </Router>
    )
}