import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { Empty, Space, Tag } from "antd";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const LineChart = ({ labels, values }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const hasData = labels?.length > 0 && values?.length > 0;

    if (!hasData) {
        return <Empty description="No Data Available" />;
    }

    // Find maximum value
    const maxValue = Math.max(...values);

    let stepSize = 1_000;
    if (maxValue > 1_000_000_000) stepSize = 200_000_000; // 200M steps for billions
    else if (maxValue > 500_000_000) stepSize = 100_000_000; // 100M steps
    else if (maxValue > 100_000_000) stepSize = 50_000_000; // 50M steps
    else if (maxValue > 10_000_000) stepSize = 10_000_000; // 10M steps
    else if (maxValue > 1_000_000) stepSize = 1_000_000; // 1M steps
    else if (maxValue > 100_000) stepSize = 100_000; // 100K steps
    else if (maxValue > 50_000) stepSize = 10_000; // 10K steps
    else if (maxValue > 10_000) stepSize = 5_000; // 5K steps
    else if (maxValue > 1_000) stepSize = 1_000; // 1K steps
    else if (maxValue > 500) stepSize = 100; // 100 steps
    else if (maxValue > 100) stepSize = 50; // 50 steps
    else if (maxValue > 10) stepSize = 10; // 10 steps
    else stepSize = 1; // 1 step for very small values



    const suggestedMax = Math.ceil(maxValue / stepSize) * stepSize;


    const formatNumber = (value) =>
        new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(value);

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Total Amount",
                data: values,
                borderColor: "#003366",
                backgroundColor: "#004080",
                borderWidth: 2,
                pointRadius: 5,
                pointBackgroundColor: "#003366",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: stepSize,
                    callback: (value) => formatNumber(value),
                },
                suggestedMax: suggestedMax,
                grid: { color: "rgba(200, 200, 200, 0.2)" },
            },
            x: {
                grid: { display: false },
            },
        },
        animation: {
            duration: 800,
            easing: "easeInOutQuart",
        },
    };

    return (
        <>

            <Space
                size="middle"
                direction={isMobile ? "vertical" : "horizontal"}
                align="center"
                style={{
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    width: "100%",
                }}
            >
                {labels.map((label, index) => (
                    <Tag key={index} color="#004080" style={{ color: "white" }}>
                        {label}: {values[index]}
                    </Tag>
                ))}
            </Space>

            <div className="h-80">
                <Line data={data} options={options} />
            </div>
        </>
    );
};

export default LineChart;
