import { Button, Card, Col, DatePicker, Form, notification, Row, Select, Typography, Table, Space, Tag, Progress, Tooltip } from 'antd';
import React, { useEffect, useState } from "react";
import { BarChartOutlined, CloseCircleOutlined, LineChartOutlined } from '@ant-design/icons';
import ajaxService from '../../../services/ajax-services';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useWindowSize } from '../../../hooks';
import BarChart from '../../charts/barchart';
import LineChart from '../../charts/linechart';

dayjs.extend(customParseFormat);


const { Title, Text } = Typography;
const { Option } = Select;


const CategoryReport = () => {

    const { width } = useWindowSize()

    const isMobile = width <= 768

    const [form] = Form.useForm();
    const [entities, setEntities] = useState([]);
    const [categories, setCategories] = useState([])
    const [dataSource, setDataSource] = useState([]);



    const maxAmount = Math.max(...dataSource.map(item => Math.abs(item.amount)));


    console.log(maxAmount);

    const columns = [
        {
            title: 'For the Month',
            dataIndex: 'for_the_month',
            key: 'for_the_month',
            render: (text) => {
                const date = new Date(text);
                return `${date.getFullYear()}-${date.toLocaleString('en-US', { month: 'long' })}`;
            }
        },
        {
            title: 'Chart',
            dataIndex: 'amount',
            key: 'chart',
            render: (amount) => {

                const normalizedAmount = Math.abs(amount);
                const percentage = Math.min((normalizedAmount / maxAmount) * 100);
                return (
                    <Tooltip
                        title={<span style={{ fontWeight: "bold", color: "#fff" }}>Progress: {percentage.toFixed(6)}%</span>}
                        color="#D2042D"
                        placement="top"
                    >
                        <Progress
                            percent={percentage}
                            status="exception"
                            showInfo={false}
                            strokeColor="#D2042D"
                            trailColor="transparent"
                        />
                    </Tooltip>

                );
            }
        },


        {
            title: 'Total Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => {
                const actual = Math.abs(amount);
                return (

                    <Text>{actual}</Text>
                );
            }
        },
    ];

    const handleSearch = async () => {
        try {
            const values = await form.validateFields();
            let formData = {};
            if (values.entity_id) {
                formData['entity'] = values.entity_id;
            }
            if (values.category_id) {
                formData['category'] = values.category_id;
            }

            if (values.for_the_month) {
                formData['for_the_month'] = values.for_the_month.format('YYYY-MM');
            }
            if (values.to_the_month) {
                formData['to_the_month'] = values.to_the_month.format('YYYY-MM');
            }

            const response = await ajaxService.filter('categoryreport/filter', formData);
            if (response !== undefined) {
                setDataSource(response);
            } else {
                notification.open({
                    message: 'No transaction found...',
                    icon: <CloseCircleOutlined style={{ color: 'red' }} />,
                });
            }
        } catch (error) {
            console.log("Validation failed:", error);
        }
    };

    const handleClear = () => {
        form.resetFields();
        setDataSource([]);
    };

    const loadEntities = async () => {
        const response = await ajaxService.get('entities');
        if (response) {
            const data = response.filter(i => i.active === 1);
            setEntities(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
    };

    const loadCategories = async () => {
        let response = await ajaxService.get('categories')
        if (response) {
            let data = response.filter(i => i.active == 1)
            setCategories(data.sort((a, b) => a.name.localeCompare(b.name)))
        }
    }

    useEffect(() => {
        loadEntities();
        loadCategories();
    }, []);


    const renderFilters = () => {
        return (
            <Card style={{ marginBottom: 16 }}>
                <Title level={4}>Category Report</Title>
                <Form form={form} layout="vertical" style={{ marginTop: "20px" }}>
                    <Row gutter={[16, 16]} align="middle" justify="start">
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item name="entity_id" label="Select Entity">
                                <Select placeholder="Select Entity" showSearch allowClear style={{ width: "100%" }}>
                                    {entities.map(entity => (
                                        <Option key={entity.id} value={entity.id}>
                                            {entity.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={4}>
                            <Form.Item name="category_id" label="Select Category">
                                <Select placeholder="Select Category" showSearch allowClear style={{ width: "100%" }}>
                                    {categories.map(category => (
                                        <Option key={category.id} value={category.id}>
                                            {category.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item name="for_the_month" label="From Month" >
                                <DatePicker picker="month" style={{ width: '100%' }} />
                            </Form.Item>

                        </Col>


                        <Col xs={24} sm={12} md={4}>
                            <Form.Item name="to_the_month" label="To Month" >
                                <DatePicker picker="month" style={{ width: '100%' }} />
                            </Form.Item>

                        </Col>

                        <Col xs={24} sm={24} md={6}>
                            <Form.Item label="&nbsp;">
                                <Space>
                                    <Button type="primary" onClick={handleSearch}>Search</Button>
                                    <Button onClick={handleClear}>Clear</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    };




    const renderCategoriesTable = () => {
        return (
            <Table columns={columns} dataSource={dataSource} rowKey="for_the_month" />
        );
    };

    const renderBarChart = () => {
        const labels = dataSource.map(item => {
            const date = new Date(item.for_the_month);
            return `${date.getFullYear()}-${date.toLocaleString('en-US', { month: 'long' })}`;
        });

        const values = dataSource.map(item => Math.abs(item.amount));


        return (
            <Row gutter={[16, 16]} justify="center" className="mt-4">
                {/* Bar Chart */}
                <Col xs={24} md={12}>
                    <Card title={<><BarChartOutlined /> Bar Chart</>}>
                        <BarChart labels={labels} values={values} />
                    </Card>
                </Col>

                {/* Line Chart */}
                <Col xs={24} md={12}>
                    <Card title={<><LineChartOutlined /> Line Chart</>}>
                        <LineChart labels={labels} values={values} />
                    </Card>
                </Col>
            </Row>


        );
    };

    const renderCategoriesMobile = () => {
        return (
            <Row gutter={[16, 16]}>
                {dataSource && dataSource.length > 0 ? (
                    dataSource.map((item) => (
                        <Col xs={24} key={item.key || item.id}>
                            <Card
                                title={`For the Month: ${new Date(item.for_the_month).getFullYear()} - ${new Date(item.for_the_month).toLocaleString("en-US", { month: "long" })}`}
                            >
                                <Title level={5}>Total Amount:</Title>
                                <Text>{item.amount?.toLocaleString() || "N/A"}</Text>
                                <Title level={5}>Chart:</Title>
                                <Progress
                                    percent={Math.min((Math.abs(item.amount || 0) / 10000) * 100, 100)}
                                    status="exception"
                                    showInfo={false}
                                    strokeColor="#ff4d4f"
                                    trailColor="transparent"
                                />
                            </Card>
                        </Col>
                    ))
                ) : (
                    <Col xs={24}>
                        <Card>
                            <Text type="secondary">No Data Found</Text>
                        </Card>
                    </Col>
                )}
            </Row>
        );
    };





    const renderCategoryList = () => {
        return (
            <>
                {renderFilters()}

                {isMobile ? (
                    renderCategoriesMobile()
                ) : (
                    renderCategoriesTable()
                )}
                {renderBarChart()}

            </>
        );
    };

    return <>{renderCategoryList()}</>;


};

export default CategoryReport;
