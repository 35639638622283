
import { Button, Card, Modal, notification, Space, Switch, Table, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ajaxService from '../../services/ajax-services';
import { useWindowSize } from '../../hooks';

const { Title, Text } = Typography

function Entities() {


    const { width } = useWindowSize()

    const isMobile = width <= 768
    const [showModal, setShowModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    let history = useHistory()

    const updateValue = async (id, val, type) => {
        const active = await ajaxService.put('entities/', { type: type, value: val }, id)
        if (active) {
            if (val === true) {
                notification.open({ message: 'Entity activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (val === false) {
                notification.open({ message: 'Entity deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'title',
            width: '75%',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => {
                return (<Switch
                    onChange={(val) => updateValue(record.id, val, 'active')}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={record.active == 1 ? true : false} />)
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button type='primary' onClick={() => history.push({ pathname: '/entities/form/' + record.id })}>Edit</Button>
                        <Button type='primary' danger onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>)
            }
        },
    ]

    const deleteRow = async () => {
        let entity_id = localStorage.getItem('entity')

        let values = {
            is_delete: 1
        }
        let item = await ajaxService.put('entities/', values, entity_id)
        if (item) {
            initialize()
            setShowModal(false)
            notification.open({ message: 'Entity has been deleted successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('entity', id);
        setShowModal(true)
    }

    const initialize = async () => {
        setDataSource([])
        let data = await ajaxService.get('entities')
        if (data) {
            setDataSource(data)
        }
    }

    useEffect(() => {
        initialize()
    }, [])


    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Entities</Title>
                    <Space size="small" >
                        <Button className='addBtn' type="primary" shape="round"
                            onClick={() => history.push({ pathname: '/entities/form/0' })}>
                            Add Entity
                        </Button>
                    </Space>
                </div>
            </>
        )
    }

    const renderModal = () => {
        return (
            <Modal
                title="Delete Entity"
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button type='primary' danger key="delete" onClick={() => deleteRow()}>
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this entity?</p>
                </div>
            </Modal>
        )
    }

    const renderEntities = () => {
        return (
            <>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                />

            </>
        );
    }

    const renderEntitiesMobile = () => {
        return (
            <>
                {
                    dataSource.map(entity => {
                        return (
                            <>
                                <br />
                                <Card
                                    style={{ width: 330 }}
                                    actions={[
                                        <Space size='small'>
                                            <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: '/entities/form/' + entity.id })}>Edit</Button>
                                            <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(entity.id)}>Delete</Button>
                                        </Space>
                                    ]}>
                                    <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{entity.name}</span></Text>
                                    <br /><br />
                                    <Text style={{ fontWeight: 'bolder' }}>Active :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                            <Switch
                                                onChange={(val) => updateValue(entity.id, val, 'active')}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                defaultChecked={entity.active == 1 ? true : false}
                                            />
                                        </span>
                                    </Text>
                                    <br />
                                </Card>
                                <br />
                            </>
                        );
                    })
                }
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderEntitiesMobile()
        } else {
            return renderEntities()
        }
    }

    return (
        <>
            {isMobile && renderHeader()}
            {renderContent()}
            {renderModal()}
        </>
    )

}

export default Entities