import React from 'react';
import { Card, Form, Select, DatePicker, Button, Typography } from 'antd';
const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const MobileFilter = ({ form, entities, handleSearch, handleClear }) => {
    return (
        <Card style={{ marginBottom: 16 }}>
            <Title level={4}>Categorized Report</Title>
            <Form form={form} layout="vertical">
                <Form.Item label="Select Entity" name="entity_id">
                    <Select
                        placeholder="Select Entity"
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {entities.map(entity => (
                            <Option key={entity.id} value={entity.id}>
                                {entity.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Date Range" name="dateRange">
                    <RangePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" block onClick={handleSearch}>
                        Search
                    </Button>
                    <Button block onClick={handleClear} style={{ marginTop: 8 }}>
                        Clear
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default MobileFilter;
