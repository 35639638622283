import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Layout, Menu, Drawer, Button, Grid } from "antd";
import {
    AppstoreAddOutlined,
    AccountBookOutlined,
    PullRequestOutlined,
    ReadOutlined,
    LinkOutlined,
    LoginOutlined,
    UserOutlined,
    FileTextOutlined,
    MenuOutlined,
} from "@ant-design/icons";

const { Header } = Layout;
const { useBreakpoint } = Grid;

export default function MainHeader() {
    const history = useHistory();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const screens = useBreakpoint(); // Responsive breakpoints

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("loggedInUser");
        history.push("/");
    };

    // Determine menu mode based on screen size
    const menuMode = screens.md ? "horizontal" : "inline";

    const menuItems = [
        {
            key: "1",
            icon: <span className="header-icons"><AppstoreAddOutlined /></span>,
            label: <Link to="/">Dashboard</Link>,
        },
        {
            key: "2",
            icon: <span className="header-icons"><ReadOutlined /></span>,
            label: <Link to="/categories">Categories</Link>,
        },
        {
            key: "3",
            icon: <span className="header-icons"><LinkOutlined /></span>,
            label: <Link to="/entities">Entities</Link>,
        },
        {
            key: "4",
            icon: <span className="header-icons"><AccountBookOutlined /></span>,
            label: <Link to="/accounts">Accounts</Link>,
        },
        {
            key: "5",
            icon: <span className="header-icons"><PullRequestOutlined /></span>,
            label: <Link to="/transactions">Transactions</Link>,
        },
        {
            key: "6",
            icon: <span className="header-icons"><FileTextOutlined /></span>,
            label: "Reports",
            children: [
                {
                    key: "6-1",
                    label: <Link to="/reports/monthly-categorical-reports">Monthly Categorical Report</Link>,
                },
                {
                    key: "6-2",
                    label: <Link to="/reports/category-reports">Category Report</Link>,
                },
            ],
        },
        {
            key: "7",
            icon: <span className="header-icons"><UserOutlined /></span>,
            label: <Link to="/change-password">Change Password</Link>,
        },
        {
            key: "8",
            icon: <span className="header-icons"><LoginOutlined /></span>,
            label: "Logout",
            onClick: logout,
        },
    ];


    return (
        <Header style={{ background: "#003366" }}>
            {screens.md ? (
                <Menu mode={menuMode} style={{ background: "#003366", color: "#FFF" }} items={menuItems} />
            ) : (
                <>
                    <Button
                        type="primary"
                        icon={<MenuOutlined />}
                        style={{ marginLeft: "10px" }}
                        onClick={() => setDrawerVisible(true)}
                    />
                    <Drawer
                        title="Menu"
                        placement="left"
                        closable={true}
                        onClose={() => setDrawerVisible(false)}
                        open={drawerVisible}
                        styles={{ body: { padding: 0 } }}
                    >
                        <Menu mode="inline" items={menuItems} />
                    </Drawer>
                </>
            )}
        </Header>
    );
}
