
import { Button, Card, Input, Modal, notification, Space, Switch, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, CheckOutlined, CloseOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ajaxService from '../../services/ajax-services';
import { useWindowSize } from '../../hooks';

const { Title, Text } = Typography

function Categories() {

    const { width } = useWindowSize()

    const isMobile = width <= 768
    const [showModal, setShowModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [budgetValues, setBudgetValues] = useState({});

    let history = useHistory()

    const updateValue = async (id, val, type) => {
        const active = await ajaxService.put('categories/', { type: type, value: val }, id)
        if (active) {
            if (val === true) {
                initialize()
                notification.open({ message: 'Category activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
            else if (type == 'budget') {
                initialize()
                notification.open({ message: 'Budget Updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
            else if (val === false) {
                notification.open({ message: 'Category deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }


    const handleBudgetChange = (id, value) => {
        setBudgetValues((prev) => ({
            ...prev,
            [id]: value
        }));
    };


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'title',
            width: '65%',
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            render: (text, record) => {
                const budgetValue = budgetValues[record.id] ?? parseFloat(record.budget).toFixed(2);

                const handleBlurOrEnter = async () => {
                    if (budgetValue !== parseFloat(record.budget).toFixed(2)) {
                        await updateValue(record.id, budgetValue, 'budget');
                    }
                };

                return (
                    <Input
                        type="number"
                        step="0.01"
                        size="small"
                        style={{ height: '40px', width: '80px' }}
                        value={budgetValue}
                        onChange={(e) => handleBudgetChange(record.id, e.target.value)}
                        onBlur={handleBlurOrEnter}
                        onKeyDown={(e) => e.key === 'Enter' && handleBlurOrEnter()}
                    />
                );
            }
        }
        ,
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => {
                return (
                    <Switch id={record.id}
                        onChange={(val) => updateValue(record.id, val, 'active')}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={record.active ? true : false}
                    />
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button type='primary' onClick={() => history.push({ pathname: '/categories/form/' + record.id })}>Edit</Button>
                        <Button type='primary' danger onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>
                )
            }
        },
    ]

    const deleteRow = async () => {
        let category_id = localStorage.getItem('category')
        let values = {
            is_delete: 1
        }
        let item = await ajaxService.put('categories/', values, category_id)
        if (item) {
            initialize()
            setShowModal(false)
            notification.open({ message: 'Category has been deleted successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('category', id);
        setShowModal(true)
    }

    const initialize = async () => {

        setDataSource([])
        let data = await ajaxService.get('categories')
        if (data) {
            setDataSource(data)
        }
    }


    useEffect(() => {
        initialize()
    }, [])

    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Categories</Title>
                    <Space size="small" >
                        <Button className='addBtn' type="primary" shape="round"
                            onClick={() => history.push({ pathname: '/categories/form/0' })}>Add Category</Button>
                    </Space>
                </div>
            </>
        )
    }

    const renderModal = () => {
        return (
            <Modal
                title="Delete Category"
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button type='primary' danger key="delete" onClick={() => deleteRow()}
                    >
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this category?</p>
                </div>
            </Modal>
        )
    }

    const renderCategories = () => (
        <Table
            dataSource={dataSource}
            columns={columns}
            title={() => renderHeader()}
            pagination={false}
            summary={() => {
                const totalBudget = dataSource.reduce((sum, category) => sum + parseFloat(category.budget || 0), 0).toFixed(2);
                return (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={1} />
                        <Table.Summary.Cell index={1} ><Text strong style={{ fontSize: "14px", color: "blue" }}>{totalBudget}</Text></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} colSpan={2} />
                    </Table.Summary.Row>
                );
            }}
        />
    );


    // For Mobile
    const renderCategoriesMobile = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
                {dataSource.map(category => (
                    <Card
                        key={category.id}
                        style={{ width: '85%', maxWidth: 300 }}
                        actions={[
                            <Space size="small">
                                <Button size="small" type="primary" icon={<EditOutlined />} onClick={() => history.push('/categories/form/' + category.id)} />
                                <Button size="small" type="primary" danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(category.id)} />
                            </Space>
                        ]}
                    >
                        <Space direction="vertical" size={6} style={{ width: '100%' }}>
                            <Text strong>Name:</Text>
                            <Text>{category.name}</Text>

                            <Text strong>Budget:</Text>
                            <Input
                                type="number"
                                size="small"
                                value={budgetValues[category.id] ?? category.budget}
                                onChange={(e) => handleBudgetChange(category.id, e.target.value)}
                                onBlur={() => updateValue(category.id, budgetValues[category.id], 'budget')}
                            />

                            <Text strong>Active:</Text>
                            <Switch
                                onChange={(val) => updateValue(category.id, val, 'active')}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={category.active == 1}
                                size="small"
                            />
                        </Space>
                    </Card>
                ))}
            </div>
        );
    };


    const renderContent = () => {
        if (isMobile) {
            return renderCategoriesMobile()
        } else {
            return renderCategories()
        }
    }

    return (
        <>
            {isMobile && renderHeader()}
            {renderContent()}
            {renderModal()}
        </>
    )
}

export default Categories