import { Button, Card, Col, Form, Input, notification, Row } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ajaxService from "../../services/ajax-services";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useWindowSize } from "../../hooks";

function ForgotPassword() {

    const { width } = useWindowSize()

    const isMobile = width <= 768
    let history = useHistory()
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const response = await ajaxService.post('password/email', values)
        if (response.errors) {
            notification.open({ message: response.errors.email, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        } else {
            form.resetFields();
            notification.open({ message: response.message, icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const renderForgotPassword = () => {
        return (
            <>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
                    <Col span={3}></Col>
                    <Col span={18}>
                        <Row>
                            <Col span={6}></Col>
                            <Col span={8}>
                                <Card title="Password Recovery" variant="outlined" style={{ width: '100%', }}
                                    actions={[
                                        <a style={{ color: '#4687F2' }} onClick={() => history.push({ pathname: '/register' })}>Need account ? Register here</a>
                                    ]}>
                                    {/* { responseMessage && <p style={{ color: '#09BC2A' }} ><b>{responseMessage}</b></p>} */}
                                    <p style={{ color: '#9A9FA2' }}>Enter your email address and we will send you a link to reset your password.</p>
                                    <Form
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        form={form}
                                    >
                                        <Form.Item
                                            labelCol={{ span: 24 }}
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message: 'Please input your email',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter email address" />
                                        </Form.Item>
                                        <a onClick={() => history.push({ pathname: '/login' })}>Return to login</a>
                                        <br />
                                        <br />
                                        <Button type="primary" style={{ marginLeft: '50px' }} htmlType="submit">
                                            Reset Password
                                        </Button>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
    const renderForgotPasswordMobile = () => {
        return (
            <>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
                    <Col span={24}>
                        <Card title="Password Recovery" variant="outlined" style={{ width: '100%', }}
                            actions={[
                                <a style={{ color: '#4687F2' }} onClick={() => history.push({ pathname: '/register' })}>Need account ? Register here</a>
                            ]}>
                            {/* { responseMessage && <p style={{ color: '#09BC2A' }} ><b>{responseMessage}</b></p>} */}
                            <p style={{ color: '#9A9FA2' }}>Enter your email address and we will send you a link to reset your password.</p>
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                form={form}
                            >
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: 'Please input your email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter email address" />
                                </Form.Item>
                                <a onClick={() => history.push({ pathname: '/login' })}>Return to login</a>
                                <Button style={{ marginLeft: '6px' }} type="primary" htmlType="submit">
                                    Reset Password
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderForgotPasswordMobile()
        } else {
            return renderForgotPassword()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}
export default ForgotPassword