import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Input, Button, Checkbox, Card, Row, Col, notification, } from 'antd';
import ajaxService from '../../services/ajax-services';
import { CheckCircleOutlined, CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useWindowSize } from '../../hooks';


const Login = () => {


    const { width } = useWindowSize()
    const [captcha, setCaptcha] = useState("");


    const isMobile = width <= 768
    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/" } };



    useEffect(() => {
        fetchCaptcha();
    }, []);


    const fetchCaptcha = async () => {
        const captchaHtml = await ajaxService.getCaptcha();
        if (captchaHtml) {
            setCaptcha(captchaHtml);
        }
    };



    const onFinish = async (values) => {
        try {

            console.log(captcha);
            let captcha_original = captcha.split("?").pop();
            const response = await ajaxService.getAccessToken(values.email, values.password, values.captcha, captcha_original);

            // Handle API error responses first
            if (response.message) {
                notification.open({
                    message: response.message,
                    icon: <CloseCircleOutlined style={{ color: 'red' }} />
                });
                return;
            }
            if (!response.data || !response.data.token || !response.data.user) {
                notification.open({
                    message: "Something went wrong. Please try again.",
                    icon: <CloseCircleOutlined style={{ color: 'red' }} />
                });
                return;
            }

            const { token, user } = response.data;
            const { verified, id } = user;

            if (verified) {
                localStorage.setItem('token', token);
                localStorage.setItem('loggedInUser', id);
                history.replace(from);
            } else {
                history.replace({ pathname: "/pandingAproval", search: '?via=login' });
            }
        } catch (error) {

            let errorMessage = "Something went wrong. Please try again.";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }

            notification.open({
                message: errorMessage,
                icon: <CloseCircleOutlined style={{ color: 'red' }} />
            });
        }
    };


    // const onFinish = async (values) => {
    //     const response = await ajaxService.getAccessToken(values.email, values.password);
    //     console.log(response);
    //     if (response.message) {
    //         notification.open({ message: response.message, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
    //     }

    //     let { token, user } = response.data;
    //     let { verified, id } = user;

    //     if (response && verified) {
    //         localStorage.setItem('token', token);
    //         localStorage.setItem('loggedInUser', id);
    //         history.replace(from);
    //     }
    //     else {
    //         history.replace({ pathname: "/pandingAproval", search: '?via=login' })
    //     }
    // };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (location.search === '?via=password-reset') {
            notification.open({ message: 'Your password has been changed', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    }, [])

    const renderLogin = () => {
        return (
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
                <Col span={3}></Col>
                <Col span={18}>
                    <Row>
                        <Col span={8}>

                        </Col>
                        <Col span={8}>
                            <Card title="Sign in to your account" variant="outlined" style={{ width: '100%' }}
                                actions={[
                                    <a style={{ color: '#4687F2' }} onClick={() => history.push({ pathname: '/register' })}>Register here</a>
                                ]}>
                                <Form
                                    //{...layout}
                                    layout={'vertical'}
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Email is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                min: 8,
                                                message: "Password must be at least 8 characters",
                                            },
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    {/* <Form.Item label="Captcha" name="captcha" rules={[{ required: true, message: 'Captcha is required' }]}>
                                        <Row gutter={8} align="middle">

                                            <Col>
                                                {captcha && <img src={captcha} alt="CAPTCHA" />}

                                            </Col>
                                            <Col flex="auto">
                                                <Input placeholder="Enter Captcha" />
                                            </Col>

                                            <Col>
                                                <Button type="link" icon={<ReloadOutlined />} onClick={fetchCaptcha}>Reload</Button>
                                            </Col>
                                        </Row>
                                    </Form.Item> */}


                                    {/* <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox>Keep me signed in</Checkbox>
                                    </Form.Item> */}

                                    <a onClick={() => history.push({ pathname: '/forgotPassword' })}>Forgot Password?</a>
                                    <Button style={{ marginLeft: 35 }} type="primary" htmlType="submit">
                                        Submit
                                    </Button>

                                </Form>
                                <center>

                                </center>
                            </Card>
                        </Col>
                        <Col span={8}>

                        </Col>
                    </Row>

                </Col>
                <Col span={3}></Col>
            </Row>
        );
    }

    const renderLoginMobile = () => {
        return (
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >


                <Col span={24}>
                    <Card title="Sign in to your account" variant="outlined" style={{ width: '100%' }}
                        actions={[
                            <a style={{ color: '#4687F2' }} onClick={() => history.push({ pathname: '/register' })}>Register here</a>
                        ]}>
                        <Form
                            //{...layout}
                            layout={'vertical'}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        min: 8,
                                        message: "Password must be at least 8 characters",
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <a onClick={() => history.push({ pathname: '/forgotPassword' })}>Forgot Password?</a>
                            <Button style={{ marginLeft: 40 }} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderLoginMobile()
        } else {
            return renderLogin()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default Login